import {WebsocketMessageType} from "@/constants/WebsocketMessageType";
import Store from '@/store';
import OperationStatus from "@/model/OperationStatus";

export default class WebsocketMessageHandler {
  public static async [WebsocketMessageType.HEARTBEAT](payload: string): Promise<void> {
    // console.log('Received heartbeat with timestamp: ', payload);
  }

  public static async [WebsocketMessageType.OPERATION](payload: string): Promise<void> {
    console.log('Received Operation Plan State update: ', payload);
    const operationStatus: OperationStatus = this.constructOperationStatusFromJsonString(payload);
    // if (operationStatus.getOperationId === Store.getters.trialConfigStore.getOperationForSelectedTrial) {
      await Store.dispatch.operationStatusStore.updateCurrentOperationState(operationStatus.getState);
      await Store.dispatch.operationStatusStore.updateRejectionReason(operationStatus.getRejectionMessage)
    // }
  }

  private static constructOperationStatusFromJsonString(jsonString: string): OperationStatus {
    const json: any = JSON.parse(jsonString);
    return new OperationStatus(json.operationId, json.state, json.rejectionMessage);
  }
}