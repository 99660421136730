












import Vue from 'vue';
import Keycloak, {KeycloakInstance} from "keycloak-js";
import AppConfig from "@/config/AppConfig";
import Modal from "@/components/modal/Modal.vue";
import Header from "@/components/header/Header.vue";
import TrialDashboard from "@/components/trial-dashboard/TrialDashboard.vue";
import ControlsContainer from "@/components/controls-container/ControlsContainer.vue";
import Popup from "@/components/common/popup/Popup.vue";

export default Vue.extend({
  name: 'App',
  components: {
    Modal: Modal,
    Header: Header,
    TrialDashboard: TrialDashboard,
    ControlsContainer: ControlsContainer,
    Popup: Popup
  },
  mounted: async function() {
    console.log("Application is mounted.");

    await this.login();
    await this.$store.direct.dispatch.websocketStore.connectToWebsocket();

    setInterval(
      async () => await this.$store.direct.dispatch.websocketStore.sendHeartbeat(),
      2000
    );
  },
  computed: {
    isAuthenticated(): boolean | undefined {
      return this.$store.direct.getters.authStore.keycloakInstance !== null &&
        this.$store.direct.getters.authStore.keycloakInstance.authenticated;
    }
  },
  methods: {
    async login(): Promise<void> {
      const keycloak: KeycloakInstance = Keycloak(AppConfig.keycloakConfig);
      try {
        await keycloak.init({onLoad: "check-sso", checkLoginIframeInterval: 1});
        if (keycloak.authenticated) {
          const kctoken: string = keycloak.token == null ? "" : keycloak.token;
          sessionStorage.setItem("kctoken", kctoken);
          this.$store.direct.dispatch.authStore.updateToken(keycloak);
          setInterval(() => {
            keycloak.updateToken(10).catch(() => keycloak.logout());
            const kctoken: string =
              keycloak.token == null ? "" : keycloak.token;
            sessionStorage.setItem("kctoken", kctoken);
            this.$store.direct.dispatch.authStore.updateToken(keycloak);
          }, 10000);
        } else {
          keycloak.login();
        }
        return await Promise.resolve();
      } catch (e) {
        console.error("An error occurred when logging in: ", e);
        return await Promise.reject(e);
      }
    }
  }
});
