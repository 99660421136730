import Store from '@/store';
import OperationProcessingResult from "@/model/OperationProcessingResult";
import OperationApi from "@/api/OperationApi";

class OperationService {

  public async createOperationForTrial(trialConfigId: string): Promise<void> {
    const operationProcessingResult: OperationProcessingResult | null =
      await OperationApi.createOperationForTrial(trialConfigId);
    const operationPlanId: string | null = operationProcessingResult?.getOperationPlanId || null;
    if(operationPlanId){
      console.log("OperationPlan created with GUFI: " + operationPlanId);
      await Store.dispatch.trialConfigStore.setOperationForSelectedTrial(operationPlanId);
    } else {
      console.log("Could not create OperationPlan for trial: " + trialConfigId);
    }
  }

  public async requestTakeOff(trialId: string): Promise<void> {
    await OperationApi.requestTakeOff(trialId);
  }

  public async declareEndOfFlight(trialId: string): Promise<void> {
    await OperationApi.declareEndOfFlight(trialId);
  }

}

export default new OperationService();