import BaseApi from "@/api/BaseApi";
import {ApiEndpoints} from "@/constants/ApiEndpoints";
import OperationProcessingResult from "@/model/OperationProcessingResult";

class OperationApi extends BaseApi {

  public async subscribeForOperation(gufi: string): Promise<void> {
    await this.post(ApiEndpoints.OPERATION_SUBSCRIBE, undefined, [gufi], undefined);
    return Promise.resolve();
  }

  public async getOperationForTrial(trialConfigId: string | undefined): Promise<string | null> {
    const response: Response = await this.get(ApiEndpoints.OPERATION_FOR_TRIALS, [trialConfigId || '']);
    if (response.status === 200) {
      return Promise.resolve(response.text());
    } else {
      return null;
    }
  }

  public async createOperationForTrial(trialId: string): Promise<OperationProcessingResult | null> {
    const response: Response = await this.post(ApiEndpoints.CREATE_OPERATION_FOR_TRIALS, undefined, [trialId || ''], undefined);
    if (response.status === 200) {
      return Promise.resolve(OperationApi.convertToOperationProcessingResult(response));
    }
    console.log("Error while creating new operation plan for trial: " + trialId);
    return Promise.resolve(null);
  }

  public async requestTakeOff(trialId: string): Promise<OperationProcessingResult | null> {
    const payload = new Map();
    payload.set("trialId", trialId);
    const response: Response =
        await this.post(ApiEndpoints.REQUEST_TAKE_OFF, undefined, undefined, payload);
    if (response.status === 200) {
      return Promise.resolve(OperationApi.convertToOperationProcessingResult(response));
    }
    console.log("Error while creating new operation plan for trial: " + trialId);
    return Promise.resolve(null);
  }

  public async declareEndOfFlight(trialId: string): Promise<OperationProcessingResult | null> {
    const payload = new Map();
    payload.set("trialId", trialId);
    const response: Response =
        await this.post(ApiEndpoints.DECLARE_END_OF_FLIGHT, undefined, undefined, payload);
    if (response.status === 200) {
      return Promise.resolve(OperationApi.convertToOperationProcessingResult(response));
    }
    console.log("Error while creating new operation plan for trial: " + trialId);
    return Promise.resolve(null);
  }

  private static async convertToOperationProcessingResult(response: Response): Promise<OperationProcessingResult> {
    const responseJson = await response.json();
    return new OperationProcessingResult(
        responseJson.operationPlanId
    );
  }

}

export default new OperationApi();