export default class OperationProcessingResult {
  private operationPlanId: string;

  constructor(operationPlanId: string) {
    this.operationPlanId = operationPlanId;
  }

  get getOperationPlanId(): string {
    return this.operationPlanId;
  }

  set setOperationPlanId(operationPlanId: string) {
    this.operationPlanId = operationPlanId;
  }

}