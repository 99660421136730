import AlertMessage from "@/model/AlertMessage";
import {ActionContext} from "vuex";
import {PopupMutation} from "@/constants/store/PopupMutation";

export interface PopupState {
  messages: AlertMessage[];
}

export default {
  namespaced: true as true,
  state: {
    messages: [] as AlertMessage[],
  } as PopupState,
  actions: {
    async addAlertMessage(
      {commit}: ActionContext<PopupState,PopupState>,
      message: AlertMessage
    ): Promise<void> {
      commit(PopupMutation.ADD_ALERT_MESSAGE, message);
    },
    async cleanupAlertMessage(
      {commit}: ActionContext<PopupState,PopupState>,
    ): Promise<void> {
      commit(PopupMutation.CLEANUP_ALERT_MESSAGES);
    }
  },
  mutations: {
    [PopupMutation.ADD_ALERT_MESSAGE](
      state: PopupState,
      message: AlertMessage
    ): void {
      state.messages.push(message);
    },
    [PopupMutation.CLEANUP_ALERT_MESSAGES](
      state: PopupState,
    ): void {
      const now = Date.now();
      state.messages = state.messages.filter(message => message.getAddedOn > now);
    }
  }
}