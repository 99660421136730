import BaseApi from "@/api/BaseApi";
import ModuleStatus from "@/model/ModuleStatus";
import {ApiEndpoints} from "@/constants/ApiEndpoints";
import StateReport from "@/model/StateReport";

class ModuleStatusApi extends BaseApi {
  public async getModulesStatus(trialConfigId: string | undefined): Promise<Array<ModuleStatus>> {
    const response: Response = await this.get(ApiEndpoints.MODULE_STATUS, [trialConfigId || '']);
    return Promise.resolve(ModuleStatusApi.constructModuleStatusFromJson(await response.json()));
  }

  private static constructModuleStatusFromJson(jsonModulesStatus: any[]): ModuleStatus[] {
    return jsonModulesStatus
      .map(json => new ModuleStatus(json.name, json.state, this.constructStateReportFromJson(json.stateReport)));
  }

  private static constructStateReportFromJson(jsonStateReport: any): StateReport {
    return new StateReport(jsonStateReport?.expectedResponses, jsonStateReport?.actualResponses);
  }
}

export default new ModuleStatusApi();
