import * as Keycloak from 'keycloak-js';

function getEnvPropValue(envPropName: string): string {
  const windowInstance: any = window as any;
  if (windowInstance.configs !== undefined && windowInstance.configs[envPropName] !== undefined) {
    return windowInstance.configs[envPropName];
  }
  return process.env[envPropName];
}

const securedSuffix = (getEnvPropValue('VUE_APP_HTTP_SECURE') === 'true' ? 's' : '');
const httpLabel: string = 'http' + securedSuffix;
const wsLabel: string = 'ws' + securedSuffix;
const backendServerUrl = getEnvPropValue('VUE_APP_BACKEND_SERVER_URL');

class AppConfig {
  public build = '52';
  public version = '0.0.7';

  public serviceUrl: string = httpLabel + '://' + backendServerUrl;
  public serviceWsUrl: string = wsLabel + '://' + backendServerUrl + '/websocket';
  public keycloakConfig: Keycloak.KeycloakConfig = {
    realm: process.env.VUE_APP_KEYCLOAK_REALM || 'swim',
    url: httpLabel + '://' + getEnvPropValue('VUE_APP_KEYCLOAK_AUTHENTICATION_URL') + '/auth',
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || 'dataprovider'
  };
  public smartSisApplicationUrl: string = getEnvPropValue('VUE_APP_SMARTSIS_APP_URL');
}

export default new AppConfig();