import {ModuleState} from "@/model/ModuleState";
import StateReport from "@/model/StateReport";

export default class ModuleStatus {
  private name: string;
  private state: ModuleState;
  private stateReport: StateReport;

  constructor(name: string, state: ModuleState, stateReport: StateReport) {
    this.name = name;
    this.state = state;
    this.stateReport = stateReport;
  }

  get getName(): string {
    return this.name;
  }

  get getState(): ModuleState {
    return this.state;
  }


  set setName(value: string) {
    this.name = value;
  }

  set setState(value: ModuleState) {
    this.state = value;
  }

  get getStateReport(): StateReport {
    return this.stateReport;
  }

  set setStateReport(value: StateReport) {
    this.stateReport = value;
  }
}