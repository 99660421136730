import Vue from 'vue';
import Vuex from 'vuex';
import {createDirectStore} from "direct-vuex";
import AuthStore from './modules/auth';
import ModuleStatusStore from './modules/module-status';
import OperationStatusStore from './modules/operation-status';
import TrialConfigStore from './modules/trial-config';
import WebsocketStore from './modules/websocket';
import ModalStore from './modules/modal';
import PopupStore from './modules/popup';

Vue.use(Vuex);

const {store, rootActionContext, moduleActionContext} = createDirectStore({
  modules: {
    authStore: AuthStore,
    moduleStatusStore: ModuleStatusStore,
    operationStatusStore: OperationStatusStore,
    trialConfigStore: TrialConfigStore,
    websocketStore: WebsocketStore,
    modalStore: ModalStore,
    popupStore: PopupStore,
  }
});

export default store;

export {rootActionContext, moduleActionContext};

declare module "vuex" {
  interface Store<S> {
    direct: typeof store;
  }
}
