import {OperationState} from "@/model/OperationState";

export default {
  [OperationState.PROPOSED]: 'proposed',
  [OperationState.APPROVED]: 'approved',
  [OperationState.ACTIVATED]: 'activated',
  [OperationState.CLOSED]: 'closed',
  [OperationState.TAKEOFFREQUESTED]: 'approved',
  [OperationState.TAKEOFFGRANTED]: 'approved',
  [OperationState.NO_SERVICE]: 'inactive',
  [OperationState.INACTIVETWR]: 'inactive',
};
