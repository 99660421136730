import ApiError from "@/api/ApiError";
import Store from "@/store";
import AppConfig from "@/config/AppConfig";
import {ApiEndpoints} from "@/constants/ApiEndpoints";

export default class BaseApi {
  private static async http(url: string, requestInit: RequestInit): Promise<Response> {
    const response: Response = await fetch(url, requestInit);
    if (!response.ok && response.status !== 404) {
      throw new ApiError(response, response.status);
    }
    return response;
  }

  protected async get(
    apiEndpoint: string,
    pathParams?: Array<string>,
    queryParams?: Map<string, string>
  ): Promise<Response> {
    return await BaseApi.http(
      BaseApi.getApiFullPath(apiEndpoint, pathParams, queryParams),
      {
        method: 'get',
        headers: BaseApi.constructDefaultHeaders()
      }
    );
  }

  protected async post(
    apiEndpoint: string,
    body: any,
    pathParams?: Array<string>,
    queryParams?: Map<string, string>
  ): Promise<Response> {
    return await BaseApi.http(
      BaseApi.getApiFullPath(apiEndpoint, pathParams, queryParams),
      {
        method: 'post',
        headers: BaseApi.constructDefaultHeaders(),
        body: JSON.stringify(body)
      }
    );
  }

  private static constructDefaultHeaders(): Record<string, string> {
    const token: string | undefined = Store.getters.authStore.token;
    if (token === undefined) {
      throw new Error('User token is not defined!');
    }
    return {
      'Authorization': `Bearer ${token}`,
      'Content-type': `application/json`
    };
  }

  private static getApiFullPath(
    apiEndpoint: string,
    pathParams?: Array<string>,
    queryParams?: Map<string, string>
  ): string {
    return `${AppConfig.serviceUrl}${ApiEndpoints.CONTEXT_PATH}${apiEndpoint}` +
      `${BaseApi.getPathParamsString(pathParams)}` +
      `${BaseApi.getQueryParamsString(queryParams)}`;
  }

  private static getPathParamsString(pathParams?: Array<string>): string {
    if (pathParams !== undefined) {
      return `/${pathParams.join('/')}`
    }
    return '';
  }

  private static getQueryParamsString(queryParams?: Map<string, string>): string {
    if (queryParams !== undefined) {
      const keyValuePairs = new Array<string>();
      queryParams.forEach((value: string, key: string) => keyValuePairs.push(`${key}=${value}`));
      return `?${keyValuePairs.join('&')}`;
    }
    return '';
  }
}