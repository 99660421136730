


































import Vue from 'vue';
import ModuleStatusComponent from "@/components/module-status/ModuleStatusComponent.vue";
import {ModuleState} from "@/model/ModuleState";
import ModuleStatus from "@/model/ModuleStatus";
import TrialConfigRepresentative from "@/model/TrialConfigRepresentative";

export default Vue.extend({
  name: "ModulesStatusContainer",
  components: {
    ModuleStatusComponent: ModuleStatusComponent
  },
  props: {
    selectedTrialConfig: {
      type: TrialConfigRepresentative,
      required: true,
      default: null
    }
  },
  watch: {
    selectedTrialConfig: async function (): Promise<void> {
      return this.$store.direct.dispatch.moduleStatusStore.fetchModulesStatus(this.selectedTrialConfig?.getId);
    }
  },
  async mounted(): Promise<void> {
    return this.$store.direct.dispatch.moduleStatusStore.fetchModulesStatus(this.selectedTrialConfig?.getId);
  },
  computed: {
    availableModulesStatus(): ModuleStatus[] {
      return this.$store.direct.getters.moduleStatusStore.modulesStatus as ModuleStatus[];
    },
    isDataLoading(): boolean {
      return this.$store.direct.getters.moduleStatusStore.areModuleStatusesLoading;
    },
    failedToLoadData(): boolean {
      return this.$store.direct.getters.moduleStatusStore.failedToLoadModuleStatuses;
    },
    trialFailed(): boolean {
      return this.availableModulesStatus.some(m => m.getState === ModuleState.NOT_OK);
    },
  }
});
