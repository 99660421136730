


















import Vue from 'vue';
import ModuleStatusContainer from "@/components/module-status/ModulesStatusContainer.vue";
import OperationStatusContainer from "@/components/operation-status/OperationStatusContainer.vue";
import TrialConfigList from "@/components/trial-config-list/TrialConfigList.vue";
import TrialConfigRepresentative from "@/model/TrialConfigRepresentative";

export default Vue.extend({
  name: "TrialDashboard",
  components: {
    TrialConfigList: TrialConfigList,
    ModuleStatusContainer: ModuleStatusContainer,
    OperationStatusContainer: OperationStatusContainer,
  },
  computed: {
    selectedTrialConfig(): TrialConfigRepresentative | undefined {
      return this.$store.direct.getters.trialConfigStore.selectedTrialConfigRepresentative as TrialConfigRepresentative;
    }
  }
});
