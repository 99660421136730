import {ActionContext} from 'vuex';
import {KeycloakInstance} from "keycloak-js";
import {AuthMutations} from "@/constants/store/AuthMutations";

export interface AuthState {
  keycloak: KeycloakInstance | null;
}

export default {
  namespaced: true as true,
  state: {
    keycloak: null,
  } as AuthState,
  actions: {
    updateToken({commit}: ActionContext<AuthState, AuthState>, keycloak: KeycloakInstance) {
      commit(AuthMutations.UPDATE_TOKEN, keycloak);
    },
    logout({commit}: ActionContext<AuthState, AuthState>) {
      commit(AuthMutations.LOGOUT);
    },
  },
  mutations: {
    [AuthMutations.UPDATE_TOKEN](state: AuthState, keycloak: KeycloakInstance) {
      state.keycloak = keycloak;
    },
    [AuthMutations.LOGOUT](state: AuthState) {
      state.keycloak?.logout();
    },
  },
  getters: {
    keycloakInstance: (state: AuthState): KeycloakInstance | null => state.keycloak,
    token: (state: AuthState): string | undefined => state.keycloak?.token,
  }
};