export default class TrialConfigRepresentative {
  private id: string;
  private name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  get getId(): string {
    return this.id;
  }

  get getName(): string {
    return this.name;
  }

  set setId(val: string) {
    this.id = val;
  }

  set setName(val: string) {
    this.name = val;
  }
}
