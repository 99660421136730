import TrialConfigRepresentative from "@/model/TrialConfigRepresentative";
import {ActionContext} from "vuex";
import {TrialConfigMutations} from "@/constants/store/TrialConfigMutations";
import TrialConfigApi from "@/api/TrialConfigApi";
import OperationApi from "@/api/OperationApi";

export interface TrialConfigState {
  trialConfigRepresentatives: TrialConfigRepresentative[];
  areTrialConfigRepresentativesLoading: boolean;
  failedToLoadTrialConfigRepresentatives: boolean;
  selectedTrialConfigRepresentative: TrialConfigRepresentative | null;
  operationPlanForSelectedTrial: string | null;
}

export default {
  namespaced: true as true,
  state: {
    trialConfigRepresentatives: [] as TrialConfigRepresentative[],
    areTrialConfigRepresentativesLoading: false,
    failedToLoadTrialConfigRepresentatives: false,
    selectedTrialConfigRepresentative: null,
    operationPlanForSelectedTrial: null
  } as TrialConfigState,
  actions: {
    async fetchTrialConfigRepresentatives(
      {commit, state}: ActionContext<TrialConfigState, TrialConfigState>
    ): Promise<void> {
      try {
        state.areTrialConfigRepresentativesLoading = true;
        const trialConfigRepresentatives: TrialConfigRepresentative[] =
          await TrialConfigApi.getTrialConfigRepresentatives();
        commit(TrialConfigMutations.SET_TRIAL_CONFIG_REPRESENTATIVES, trialConfigRepresentatives);
        state.areTrialConfigRepresentativesLoading = false;
        state.failedToLoadTrialConfigRepresentatives = false;
      } catch (e) {
        state.areTrialConfigRepresentativesLoading = false;
        state.failedToLoadTrialConfigRepresentatives = true;
        console.error("Error occurred while loading the Trial Configuration Representatives.", e);
      }
    },
    async selectTrialConfig(
      {commit}: ActionContext<TrialConfigState, TrialConfigState>,
      trialConfigId: string
    ): Promise<void> {
      commit(TrialConfigMutations.SET_SELECTED_TRIAL_CONFIG, trialConfigId);
      const operationPlanId: string | null = await OperationApi.getOperationForTrial(trialConfigId);
      console.log("Trial [" + trialConfigId + "] has OperationPlanId: " + operationPlanId);
      commit(TrialConfigMutations.SET_OPERATION_FOR_SELECTED_TRIAL, operationPlanId);
    },
    async setOperationForSelectedTrial(
      {commit}: ActionContext<TrialConfigState, TrialConfigState>,
      operationPlanId: string | null
    ): Promise<void> {
      commit(TrialConfigMutations.SET_OPERATION_FOR_SELECTED_TRIAL, operationPlanId);
    }
  },
  mutations: {
    [TrialConfigMutations.SET_TRIAL_CONFIG_REPRESENTATIVES](
      state: TrialConfigState,
      trialConfigRepresentatives: TrialConfigRepresentative[]
    ): void {
      state.trialConfigRepresentatives = trialConfigRepresentatives;
    },
    [TrialConfigMutations.SET_SELECTED_TRIAL_CONFIG](
      state: TrialConfigState,
      trialConfigId: string
    ): void {
      state.selectedTrialConfigRepresentative =
        state.trialConfigRepresentatives.filter(tc => tc.getId === trialConfigId)[0];
    },
    [TrialConfigMutations.SET_OPERATION_FOR_SELECTED_TRIAL](
      state: TrialConfigState,
      operationPlanId: string | null
    ): void {
      state.operationPlanForSelectedTrial = operationPlanId;
    }
  },
  getters: {
    trialConfigRepresentatives:
      (state: TrialConfigState): TrialConfigRepresentative[] => state.trialConfigRepresentatives,
    areTrialConfigRepresentativesLoading:
      (state: TrialConfigState): boolean => state.areTrialConfigRepresentativesLoading,
    failedToLoadTrialConfigRepresentatives:
      (state: TrialConfigState): boolean => state.failedToLoadTrialConfigRepresentatives,
    selectedTrialConfigRepresentative:
      (state: TrialConfigState): TrialConfigRepresentative | null => state.selectedTrialConfigRepresentative,
    getOperationForSelectedTrial:
      (state: TrialConfigState): string | null => state.operationPlanForSelectedTrial
  },
};
