export enum ApiEndpoints {
  CONTEXT_PATH = '/api',

  MODULE_STATUS = '/status',
  OPERATION_SUBSCRIBE = '/subscribeForOperation',
  TRIAL_CONFIG_REPRESENTATIVES = '/trials',
  OPERATION_FOR_TRIALS = '/operationForTrials',
  CREATE_OPERATION_FOR_TRIALS = '/createOperation',
  REQUEST_TAKE_OFF = '/requestOperationTakeOff',
  DECLARE_END_OF_FLIGHT = '/declareEndOfFlight'
}