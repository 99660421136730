import {ActionContext} from 'vuex';
import {ModalMutations} from "@/constants/store/ModalMutations";
import ModalConfig from "@/model/config/ModalConfig";
import {ExtendedVue} from "vue/types/vue";

export interface ModalState {
  isDisplayed: boolean;
  headerValue: string | null;
  contentObject: any | null;
  contentComponent: ExtendedVue<any, any, any, any, any> | null;
}

export default {
  namespaced: true as true,
  state: {
    isDisplayed: false,
    headerValue: null,
    contentObject: null,
    contentComponent: null,
  } as ModalState,
  actions: {
    async displayModal({commit}: ActionContext<ModalState, ModalState>, config: ModalConfig): Promise<void> {
      commit(ModalMutations.DISPLAY_MODAL, config);
    },
    async closeModal({commit}: ActionContext<ModalState, ModalState>): Promise<void> {
      commit(ModalMutations.CLOSE_MODAL);
    }
  },
  mutations: {
    [ModalMutations.DISPLAY_MODAL](state: ModalState, config: ModalConfig): void {
      state.headerValue = config.getHeaderValue;
      state.contentObject = config.getContentObject;
      state.contentComponent = config.getContentComponent;
      state.isDisplayed = true;
    },
    [ModalMutations.CLOSE_MODAL](state: ModalState): void {
      state.headerValue = null;
      state.contentObject = null;
      state.contentComponent = null;
      state.isDisplayed = false;
    }
  },
  getters: {
    isDisplayed: (state: ModalState): boolean => state.isDisplayed,
    headerValue: (state: ModalState): string | null => state.headerValue,
    contentObject: (state: ModalState): any | null => state.contentObject,
    contentComponent: (state: ModalState): ExtendedVue<any, any, any, any, any> | null => state.contentComponent,
  }
};
