import Store from '../store';
import WebsocketMessageHandler from "@/websocket/WebsocketMessageHandler";

export default class WebsocketEventHandler {
  public static async onOpen(): Promise<void> {
    await Store.dispatch.websocketStore.setOpen();
    console.log('Websocket connected...');
  }

  public static async onClose(): Promise<void> {
    await Store.dispatch.websocketStore.setClosed();
    console.log('Websocket disconnected. Trying to reconnect...');
    setTimeout(async () => await Store.dispatch.websocketStore.connectToWebsocket(), 500);
  }

  public static onError(wsEvent: Event): void {
    console.log('Websocket error', wsEvent);
  }

  public static onMessage(wsMessageEvent: MessageEvent): void {
    const message: string[] = wsMessageEvent.data.split('|');
    const messageType: string = message[0];
    const payload: string = message[1];
    (WebsocketMessageHandler as any)[messageType](payload);
  }
}