















import Vue from 'vue';
import ModuleStatus from '@/model/ModuleStatus';
import {ModuleState} from "@/model/ModuleState";
import ModalConfig from "@/model/config/ModalConfig";
import StateReport from "@/model/StateReport";
import StateReportView from "@/components/state-report-view/StateReportView.vue";

export default Vue.extend({
  name: "ModuleStatusComponent",
  props: {
    moduleStatus: {
      type: ModuleStatus,
      required: true,
      default: new ModuleStatus('', ModuleState.NOT_OK, new StateReport([], []))
    }
  },
  computed: {
    isOk(): boolean {
      return this.moduleStatus.getState === ModuleState.OK;
    }
  },
  methods: {
    async displayModuleStatusInformation(): Promise<void> {
      const modalConfig: ModalConfig = new ModalConfig(
        `${this.moduleStatus.getName}`,
        this.moduleStatus.getStateReport,
        StateReportView
      );
      await this.$store.direct.dispatch.modalStore.displayModal(modalConfig);
    }
  }
});
