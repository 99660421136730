import BaseApi from "@/api/BaseApi";
import TrialConfigRepresentative from "@/model/TrialConfigRepresentative";
import {ApiEndpoints} from "@/constants/ApiEndpoints";

class TrialConfigApi extends BaseApi {
  public async getTrialConfigRepresentatives(): Promise<TrialConfigRepresentative[]> {
      const response: Response = await this.get(ApiEndpoints.TRIAL_CONFIG_REPRESENTATIVES);
      return Promise.resolve(TrialConfigApi.constructTrialConfigRepresentativesFromJson(await response.json()));
  }

  private static constructTrialConfigRepresentativesFromJson(
    jsonTrialConfigRepresentatives: any[]
  ): TrialConfigRepresentative[] {
    return jsonTrialConfigRepresentatives.map(json => new TrialConfigRepresentative(json.id, json.name));
  }
}

export default new TrialConfigApi();
