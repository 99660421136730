






























import Vue, {PropType} from 'vue';

export default Vue.extend({
  name: "DropDown",
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Object,
      required: true,
    },
    getDisplayedValue: {
      type: Function as PropType<(option: unknown) => string>,
      required: true,
    },
    getValue: {
      type: Function as PropType<(option: unknown) => string>,
      required: true,
    },
    onOptionSelect: {
      type: Function as PropType<(option: unknown) => Promise<void>>,
      required: true,
    },
    isOptionSelected: {
      type: Function as PropType<(option: unknown) => boolean>,
      required: true,
    }
  },
  created() {
    document.addEventListener('click', this.onClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick);
  },
  data: () => {
    return {
      dropDownFocused: false,
    }
  },
  computed: {
    inputContent(): string {
      return this.getDisplayedValue(this.selectedOption);
    }
  },
  methods: {
    constructLabel(): string {
      return this.title + (this.title.length > 0 ? ':' : '');
    },
    onClick(event: MouseEvent): void {
      const clickedOnOptionList: boolean = (event.target as HTMLElement).id.startsWith(`${name}-drop-down-option-`);
      const clickedOnInput: boolean = (event.target as HTMLElement).id === `${this.name}-drop-down-input`;
      const clickedOnDropDown: boolean = (event.target as HTMLElement).className === "drop-down";
      this.dropDownFocused = clickedOnOptionList || clickedOnInput || clickedOnDropDown;
    }
  }
});
