<template>
  <div class="header">
    <div class="header-part left">
      <img src="logos/5gdrones_logo.png" class="drones-logo" alt="5G Drones Logo">
    </div>
    <div class="header-part right">
      <img src="logos/new_5g_logo.png" class="drones-logo" alt="New 5G Logo">
      <img src="logos/horizon_logo.png" class="horizon-logo" alt="Horizon Logo">
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: "Header"
  });
</script>

<style src="./header.sass" lang="sass"/>
