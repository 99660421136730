


























































import Vue from 'vue';
import OperationStateCard from "./OperationStateCard.vue";
import {OperationState} from "@/model/OperationState";
import AppConfig from "@/config/AppConfig";
import OperationService from "@/utils/OperationService";
import Store from "@/store";

export default Vue.extend({
  name: "OperationStatusContainer",
  components: {
    OperationStateCard: OperationStateCard
  },
  data: () => {
    return {
      subscribingForNewOperation: false,
      subscribeFailed: false,
    }
  },
  computed: {
    currentOperationState(): OperationState | null {
      return this.$store.direct.getters.operationStatusStore.currentOperationState;
    },
    availablePreviousOperationStates(): OperationState[] {
      return this.$store.direct.getters.operationStatusStore.previousOperationStates.slice().reverse();
    },
    currentOperationId(): string | null {
      return this.$store.direct.getters.trialConfigStore.getOperationForSelectedTrial;
    },
    isOperationIdSet(): boolean {
      return this.currentOperationId !== null;
    },
    smartSisApplicationUrl() {
      return AppConfig.smartSisApplicationUrl;
    },
    isCurrentOperationStateAccepted(): boolean {
      return this.currentOperationState !== null && this.currentOperationState === OperationState.APPROVED;
    },
    operationPlanStateIsNotClosed(): boolean {
      return this.currentOperationState !== null && this.currentOperationState !== OperationState.CLOSED;
    },
    wasAutoRejected(): boolean {
      return this.$store.direct.getters.operationStatusStore.rejectionReason !== "";
    },
    rejectionReason(): string | null {
      return this.$store.direct.getters.operationStatusStore.rejectionReason;
    }
  },
  methods: {
    async createFlightForTrial(): Promise<void> {
      await this.$store.direct.dispatch.operationStatusStore.clearData();
      try {
        const selectedTrial: string | undefined =
          this.$store.direct.getters.trialConfigStore.selectedTrialConfigRepresentative?.getId;
        if (selectedTrial !== undefined) {
          await OperationService.createOperationForTrial(selectedTrial);
        }
      } catch (e) {
        console.error('Error when creating new OperationPlan for trial : ', e);
      }
    },
    requestTakeoff() {
      const trialId = Store.getters.trialConfigStore.selectedTrialConfigRepresentative?.getId;
      if(trialId){
        OperationService.requestTakeOff(trialId)
      }
    },
    declareEndOfFlight() {
      const trialId = Store.getters.trialConfigStore.selectedTrialConfigRepresentative?.getId;
      if(trialId){
        OperationService.declareEndOfFlight(trialId)
      }
    }
  }
});
