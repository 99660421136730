export default class StateReport {
  private expectedResponses: string[];
  private actualResponse: string[];

  constructor(expectedResponse: string[], actualResponse: string[]) {
    this.expectedResponses = expectedResponse;
    this.actualResponse = actualResponse;
  }


  get getExpectedResponses(): string[] {
    return this.expectedResponses;
  }

  set setExpectedResponse(value: string[]) {
    this.expectedResponses = value;
  }

  get getActualResponses(): string[] {
    return this.actualResponse;
  }

  set setActualResponse(value: string[]) {
    this.actualResponse = value;
  }
}