import {ActionContext} from "vuex";
import {WebsocketMutations} from "@/constants/store/WebsocketMutations";
import AppConfig from "@/config/AppConfig";
import WebsocketEventHandler from "@/websocket/WebsocketEventHandler";

export interface WebsocketState {
  websocket: WebSocket | null;
  isConnected: boolean;
}

export default {
  namespaced: true as true,
  state: {
    websocket: null,
    isConnected: false
  } as WebsocketState,
  actions: {
    async connectToWebsocket({commit}: ActionContext<WebsocketState, WebsocketState>): Promise<void> {
      commit(WebsocketMutations.CONNECT);
    },
    async setOpen({commit}: ActionContext<WebsocketState, WebsocketState>): Promise<void> {
      commit(WebsocketMutations.SET_OPEN);
    },
    async setClosed({commit}: ActionContext<WebsocketState, WebsocketState>): Promise<void> {
      commit(WebsocketMutations.SET_CLOSED);
    },
    async sendHeartbeat({state}: ActionContext<WebsocketState, WebsocketState>): Promise<void> {
      if (state.websocket && state.websocket.readyState === state.websocket.OPEN) {
        state.websocket.send('heartbeat|' + Date.now());
      }
    }
  },
  mutations: {
    [WebsocketMutations.CONNECT](state: WebsocketState): void {
      if (state.websocket && state.websocket.readyState === state.websocket.OPEN) {
        state.websocket.close();
      }
      state.websocket = new WebSocket(AppConfig.serviceWsUrl);
      state.websocket.onmessage = WebsocketEventHandler.onMessage;
      state.websocket.onerror = WebsocketEventHandler.onError;
      state.websocket.onclose = WebsocketEventHandler.onClose;
      state.websocket.onopen = WebsocketEventHandler.onOpen;
    },
    [WebsocketMutations.SET_OPEN](state: WebsocketState): void {
      state.isConnected = true;
    },
    [WebsocketMutations.SET_CLOSED](state: WebsocketState): void {
      state.isConnected = false;
    },
  }
};
