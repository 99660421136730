import {ActionContext} from 'vuex';
import {ModuleStatusMutations} from "@/constants/store/ModuleStatusMutations";
import ModuleStatus from "@/model/ModuleStatus";
import ModuleStatusApi from "@/api/ModuleStatusApi";

export interface ModuleStatusState {
  moduleStatuses: ModuleStatus[];
  areModuleStatusesLoading: boolean;
  failedToLoadModuleStatuses: boolean;
}

export default {
  namespaced: true as true,
  state: {
    moduleStatuses: [] as ModuleStatus[],
    areModuleStatusesLoading: false,
    failedToLoadModuleStatuses: false
  } as ModuleStatusState,
  actions: {
    async fetchModulesStatus(
      {commit, state}: ActionContext<ModuleStatusState, ModuleStatusState>,
      trialConfigId: string | undefined
    ): Promise<void> {
      try {
        state.areModuleStatusesLoading = true;
        const modulesStatus: Array<ModuleStatus> = await ModuleStatusApi.getModulesStatus(trialConfigId);
        commit(ModuleStatusMutations.SET_MODULES_STATUS, modulesStatus);
        state.areModuleStatusesLoading = false;
        state.failedToLoadModuleStatuses = false;
      } catch (e) {
        state.areModuleStatusesLoading = false;
        state.failedToLoadModuleStatuses = true;
        console.error("Error occurred while loading the Module Statuses.", e);
      }
    }
  },
  mutations: {
    [ModuleStatusMutations.SET_MODULES_STATUS](state: ModuleStatusState, modulesStatus: ModuleStatus[]): void {
      state.moduleStatuses = modulesStatus;
    },
  },
  getters: {
    modulesStatus: (state: ModuleStatusState): ModuleStatus[] => state.moduleStatuses,
    areModuleStatusesLoading: (state: ModuleStatusState): boolean => state.areModuleStatusesLoading,
    failedToLoadModuleStatuses: (state: ModuleStatusState): boolean => state.failedToLoadModuleStatuses,
  }
};
