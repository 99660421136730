
























import Vue from 'vue';
import StateReport from "../../model/StateReport";

interface StateReportEntry {
  expectedResponse: string;
  actualResponse: string;
}

export default Vue.extend({
  name: "StateReportView",
  props: {
    displayedObject: {
      type: StateReport,
      required: true,
      default: new StateReport([], [])
    }
  },
  computed: {
    isStateReportAvailable(): boolean {
      return this.displayedObject &&
        (this.displayedObject.getExpectedResponses?.length > 0 || this.displayedObject.getActualResponses?.length > 0);
    },
    stateReportEntries(): StateReportEntry[] {
      const numberOfEntries: number = this.getNumberOfEntries();
      const entries: StateReportEntry[] = [];
      for (let index = 0; index < numberOfEntries; index++) {
        entries.push(this.getEntryFromIndex(index));
      }
      return entries;
    },
  },
  methods: {
    getNumberOfEntries(): number {
      const numberOfExpectedResponse: number = this.displayedObject.getExpectedResponses.length;
      const numberOfActualResponse: number = this.displayedObject.getActualResponses.length;
      return Math.max(numberOfExpectedResponse, numberOfActualResponse);
    },
    getEntryFromIndex(index: number): StateReportEntry {
      const expectedResponse: string = this.displayedObject?.getExpectedResponses[index] || '';
      const actualResponse: string = this.displayedObject?.getActualResponses[index] || '';
      return {expectedResponse, actualResponse};
    },
  }
});
