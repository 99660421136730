










import Vue from 'vue';

export default Vue.extend({
  name: 'Popup',
  computed: {
    alerts() {
      return this.$store.state.popupStore.messages;
    },
  },
  methods: {
  }
})
