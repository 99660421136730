import {ExtendedVue} from "vue/types/vue";

export default class ModalConfig {
  private readonly headerValue: string;
  private readonly contentObject: any;
  private readonly contentComponent: ExtendedVue<any, any, any, any, any>;

  constructor(headerValue: string, contentObject: any, contentComponent: ExtendedVue<any, any, any, any, any>) {
    this.headerValue = headerValue;
    this.contentObject = contentObject;
    this.contentComponent = contentComponent;
  }

  get getHeaderValue(): string {
    return this.headerValue;
  }

  get getContentObject(): any {
    return this.contentObject;
  }

  get getContentComponent(): ExtendedVue<any, any, any, any, any> {
    return this.contentComponent;
  }
}