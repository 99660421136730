






import Vue from 'vue';
import {OperationState} from "@/model/OperationState";
import OperationStateClassMapping from "@/constants/OperationStateClassMapping";

export default Vue.extend({
  name: "OperationStateCard",
  props: {
    operationState: {
      type: String as () => OperationState,
      required: true,
      default: OperationState.CLOSED
    }
  },
  computed: {
    stateClassName(): string {
      return OperationStateClassMapping[this.operationState] || '';
    }
  }
});
