

























import Vue from 'vue';
import TrialConfigRepresentative from "@/model/TrialConfigRepresentative";
import DropDown from "@/components/common/drop-down/DropDown.vue";

export default Vue.extend({
  name: "TrialConfigList",
  async mounted(): Promise<void> {
    await this.$store.direct.dispatch.trialConfigStore.fetchTrialConfigRepresentatives();
  },
  components: {
    DropDown: DropDown
  },
  computed: {
    trialConfigRepresentatives(): TrialConfigRepresentative[] {
      return this.$store.direct.getters.trialConfigStore.trialConfigRepresentatives as TrialConfigRepresentative[];
    },
    isDataLoading(): boolean {
      return this.$store.direct.getters.trialConfigStore.areTrialConfigRepresentativesLoading;
    },
    failedToLoadData(): boolean {
      return this.$store.direct.getters.trialConfigStore.failedToLoadTrialConfigRepresentatives;
    },
    selectedTrialConfigRepresentative(): TrialConfigRepresentative {
      return this.$store.direct.getters.trialConfigStore.selectedTrialConfigRepresentative as TrialConfigRepresentative;
    },
  },
  methods: {
    async onTrialConfigSelected(selectedTrialConfigRepresentative: TrialConfigRepresentative): Promise<void> {
      await this.$store.direct.dispatch.trialConfigStore.selectTrialConfig(selectedTrialConfigRepresentative?.getId);
    },
    getTrialConfigId(trialConfigRepresentative: TrialConfigRepresentative): string {
      return trialConfigRepresentative?.getId;
    },
    getTrialConfigName(trialConfigRepresentative: TrialConfigRepresentative): string {
      return trialConfigRepresentative?.getName;
    },
    isTrialConfigSelected(trialConfigRepresentative: TrialConfigRepresentative): boolean {
      return trialConfigRepresentative?.getId === this.selectedTrialConfigRepresentative?.getId;
    }
  },
});
