import {OperationState} from "@/model/OperationState";
import {ActionContext} from "vuex";
import {OperationStatusMutations} from "@/constants/store/OperationStatusMutations";

export interface OperationStatusState {
  currentOperationState: OperationState | null;
  previousOperationStates: OperationState[];
  rejectionReason: string | null;
}

export default {
  namespaced: true as true,
  state: {
    currentOperationState: null,
    previousOperationStates: [] as OperationState[],
    rejectionReason: null,
  } as OperationStatusState,
  actions: {
    async clearData(
      {commit}: ActionContext<OperationStatusState, OperationStatusState>): Promise<void> {
      try {
        commit(OperationStatusMutations.CLEAR_PERSISTED_DATA);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateCurrentOperationState(
      {commit}: ActionContext<OperationStatusState, OperationStatusState>,
      newOperationState: OperationState): Promise<void> {
      commit(OperationStatusMutations.UPDATE_CURRENT_OPERATION_STATE, newOperationState);
    },
    async updateRejectionReason(
      {commit}: ActionContext<OperationStatusState, OperationStatusState>,
      rejectionMessage: string): Promise<void> {
      commit(OperationStatusMutations.UPDATE_REJECTION_REASON, rejectionMessage);
    }
  },
  mutations: {
    [OperationStatusMutations.UPDATE_CURRENT_OPERATION_STATE](
      state: OperationStatusState,
      newOperationState: OperationState): void {
      if(newOperationState === state.currentOperationState){
        return;
      }
      if (state.currentOperationState && !state.previousOperationStates.includes(state.currentOperationState)) { //
        state.previousOperationStates.push(state.currentOperationState);
      }
      state.currentOperationState = newOperationState;
    },
    [OperationStatusMutations.CLEAR_PERSISTED_DATA](state: OperationStatusState): void {
      // state.operationId = null;
      state.previousOperationStates = [] as OperationState[];
      state.currentOperationState = null;
    },
    [OperationStatusMutations.UPDATE_REJECTION_REASON](
      state: OperationStatusState,
      rejectionMessage: string): void {
      state.rejectionReason = rejectionMessage;
    }
  },
  getters: {
    currentOperationState: (state: OperationStatusState): OperationState | null => state.currentOperationState,
    previousOperationStates: (state: OperationStatusState): OperationState[] => state.previousOperationStates,
    rejectionReason: (state: OperationStatusState): string | null => state.rejectionReason,
  }
};
