















import Vue from 'vue';
import {ExtendedVue} from "vue/types/vue";

export default Vue.extend({
  name: "Modal",
  computed: {
    isDisplayed(): boolean {
      return this.$store.direct.getters.modalStore.isDisplayed;
    },
    headerValue(): string | null {
      return this.$store.direct.getters.modalStore.headerValue;
    },
    contentObject(): any | null {
      return this.$store.direct.getters.modalStore.contentObject;
    },
    contentComponent(): ExtendedVue<any, any, any, any, any> | null {
      return this.$store.direct.getters.modalStore.contentComponent as ExtendedVue<any, any, any, any, any> | null;
    }
  },
  methods: {
    async closeModal(): Promise<void> {
      await this.$store.direct.dispatch.modalStore.closeModal();
    }
  }
});
