export default class ApiError extends Error {
  private readonly _response: Response;
  private readonly _httpCode: number;

  constructor(response: Response, httpCode: number) {
    super();
    this._response = response;
    this._httpCode = httpCode;
  }

  get response(): Response {
    return this._response;
  }

  get httpCode(): number {
    return this._httpCode;
  }
}