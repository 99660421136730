













import Vue from 'vue';

export default Vue.extend({
  name: "ControlsContainer",
  computed: {
    selectedTrialConfigId(): string | undefined {
      return this.$store.direct.getters.trialConfigStore.selectedTrialConfigRepresentative?.getId;
    }
  },
  methods: {
    async logout(): Promise<void> {
      return this.$store.direct.dispatch.authStore.logout();
    },
    async refreshModuleStatuses(): Promise<void> {
      return this.$store.direct.dispatch.moduleStatusStore.fetchModulesStatus(this.selectedTrialConfigId);
    }
  }
});
