import {OperationState} from "@/model/OperationState";

export default class OperationStatus {
  private operationId: string;
  private state: OperationState;
  private rejectionMessage: string;

  constructor(operationId: string, state: OperationState, rejectionMessage: string) {
    this.operationId = operationId;
    this.state = state;
    this.rejectionMessage = rejectionMessage;
  }

  get getOperationId(): string {
    return this.operationId;
  }

  get getState(): OperationState {
    return this.state;
  }

  set setOperationId(value: string) {
    this.operationId = value;
  }

  set setState(value: OperationState) {
    this.state = value;
  }

  get getRejectionMessage(): string {
    return this.rejectionMessage;
  }

  set setRejectionMessage(value: string) {
    this.rejectionMessage = value;
  }
}